.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.removeMarging p {
      margin-bottom: 0.3rem;

}

.tikdBlue {
  background-color: #50baf0 !important
}
.tikdBlueText {
  color:#50baf0;
}
.closePtag {
  line-height: 5px;
}

.link {
  color:#50baf0; text-decoration: underline;
}

.round{
  border-radius: 25px !important; 
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.whiteBar{
  border-right: white solid 2px;
   padding-right:25px

}
.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.closeIt:after {
  display: inline-block;
  content: "\00d7";
  /* This will render the 'X' */
}

@media print{
   .noprint{
       display:none;
   }
}